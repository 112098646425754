// Override default styles for datepicker
.datepicker {
  width: 250px;
}

.datepicker-nav {
  background-color: $cornflower !important;
}

.date-item.is-today {
  border-color: $cornflower !important;
  color: $cornflower !important;
}

.date-item:hover {
  border-color: $cornflower !important;
}

.datetimepicker-dummy-wrapper.is-active {
  border-color: $cornflower !important;
  box-shadow: 0 0 0 0.125em rgba(101, 138, 255, 0.25) !important;
}

.datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper::before,
.datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper:before {
  background-color: $cornflower !important;
}

.datetimepicker-dummy.is-primary .datetimepicker-clear-button {
  color: $cornflower !important;
}

.datetimepicker-dummy .datetimepicker-dummy-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.datetimepicker.is-primary
  .datepicker-body
  .datepicker-dates
  .datepicker-days
  .datepicker-date
  .date-item.is-active {
  background-color: $cornflower !important;
}

.datetimepicker-dummy-input::placeholder {
  color: $grey-text;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: normal;
}

@import "../../variables.scss";

.password-recovery-page {
    height: calc(100% - 66px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.password-recovery-wrapper {
    width: 700px;
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: $white;
}

.password-recovery-header {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: $black-title;
    padding: 22px 30px;
    border-bottom: 1px solid rgba(204, 211, 223, 0.35)
}

.password-recovery-form-wrapper {
    padding: 10px 32px 0 32px;
}

.field-container {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 0 !important;
}

.field-group {
    display: flex;
}

.footer-wrapper {
    padding: 32px;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.action-button {
    width: 170px;
    height: 50px!important;
}

.success-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
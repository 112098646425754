.root {
    padding: 44px;

    max-width: 1769px;

    margin: 0 auto;

    .title {
        font-family: Poppins;
        font-size: 22px;
        font-weight: 600;
        color: #242d4d;
    }

    .dashboard-section {
        padding-bottom: 0;
        padding-top: 0;
    }
}

@import '../../../../../variables.scss';

.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  z-index: 10;
}

.form-header {
  background-color: #fafafa;
  padding: 2rem;

  .form-header-top {
    display: flex;
    margin-bottom: 2rem;

    .level-right {
      .close-button {
        margin: 0 !important;
      }
    }
  }

  .card-title,
  .form-title-container {
    margin-bottom: 0 !important;
    width: 100%;
  }

  .card-title {
    outline: none;
    border: none;
    background: transparent;
    color: #555c72;
    font-weight: bold;
    margin: 0;
    box-shadow: none;

    &.editing {
      border-bottom: 1px solid white;
    }
    &:focus {
      outline: none !important;
    }
  }

  .card-options {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .card-secondary-options,
    .project-option-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .card-option:first {
      width: 35%;
    }
    .card-option {
      width: 100%;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .card-option {
      &.priority,
      &.size {
        max-width: 95px;
      }
    }
  }
}

.field-container.project-option {
  margin: 0;
  max-width: 100%;
  z-index: 11;
}

.field-container.card-option.date-option {
  z-index: 12;
}

.form-section-title {
  align-self: flex-start;
  margin: 2rem 0 0.5rem 4.5rem;
  font-size: 14px;
  font-weight: 600;
  color: #242d4d;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 40px;
}

.description {
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02) !important;
  }
}

.stamps {
  width: 100%;
  align-self: flex-start;
  padding-top: 2rem;

  .stamp-wrap {
    padding: 1rem 0;
    width: 100%;
    border-top: solid 1px #ccd3df;
    &:last-child {
      border-bottom: solid 1px #ccd3df;
    }
    .title-icon {
      margin-right: 0.5rem;
    }
    .stamp-title {
      font-size: 14px;
      font-weight: 600;
      padding-left: 4rem;
    }
    .stamp-content {
      font-size: 14px;
      font-weight: 300;
      padding-left: 4rem;
    }
  }
}

.buttons {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  * {
    margin: 1rem;
  }
  .delete-button,
  .submit-button {
    width: 170px;
    height: 50px;
    padding: 15px 61px 15px 61px;
  }
}

.field-container {
  display: flex;
  flex-direction: column;
  max-width: 566px;
  margin-top: 19px;
}

.project-error {
  position: relative !important;
}

.datepicker {
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 5%) !important;
  text-align: left;
}

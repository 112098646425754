.statistic-container {
    display: flex;
    align-items: center;
    max-width: 400px;
    height: 75px;
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    margin-bottom: 17px;
}

.statistic-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 3px;
    margin: 12px;
}

.statistic-icon-green {
    background-color: rgba(127, 203, 88, 0.2);
    color: rgb(127, 203, 88);
}

.statistic-icon-red {
    background-color: rgba(230, 78, 61, 0.2);
    color: rgb(230, 78, 61);
}

.statistic-icon-orange {
    background-color: rgba(243, 180, 45, 0.2);
    color: rgb(243, 180, 45);
}

.statistic-number {
  font-size: 20px;
  font-weight: 600;
  color: #242d4d;
}

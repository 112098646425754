@import '../../variables.scss';

.container {
  width: 100%;
  display: flex;
  align-items: center;
}

.direction-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 30px;
  margin-left: 1rem;
  color: $cornflower;
}

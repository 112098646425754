.documents {
  .document {
    border-bottom: solid 0.7px #e2e2e2;

    .document-text {
      text-decoration: none;
      color: inherit;
      font-size: 14px;
    }
  }
}

.document-form {
  padding: 18px;

  .document-button,
  .document-input {
    margin-left: 13px;
  }
}

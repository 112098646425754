@import '../../variables.scss';

.container {
  border: solid 4px $red;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 31;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $red;
  color: white;
  text-align: center;
}

.message {
  padding: 0 1rem;
  font-size: 14px;
}

.userInfo {
  font-size: 18px;
  text-decoration: underline;
}

.wrapper {
  padding: 0 38px 25px 38px;
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
}

.task-form-wrapper {
  padding: 10px;
  border: solid 0.7px #e2e2e2;

  .task-title {
    width: 100%;
  }
}

@import '../../../variables.scss';

.root {
  padding: 53px 56px 53px 44px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.subscription-wrapper {
  flex: 1;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: $white;
  padding: 21px 27px 25px 27px;
  &:nth-of-type(3) {
    margin-top: 25px;
  }
}

.subscription-column {
  width: 30%;
  margin: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.subscription-title-wrapper {
  padding: 0 0 12px 0;
}

//Other colors
$white: #ffffff;
$grey: #fafafa;
$cornflower: #658aff;
$light-cornflower: #a5baff;
$orange: #ffa34a;
$black-title: #242d4d;
$middle-grey: #c6c7c9;
$grey-text: #555c72;
$red: #c33939;
$light-sea-green: #0f9ab4;
$sea-green: #068b8f;
$dark-green: #1d863c;
$pea-green: #50b649;
$light-pea-green: #a5eda0;
$purple: #4c1a63;

// Bulma default colours
$progress-value-background-color: $cornflower;
$tabs-link-active-border-bottom-color: $cornflower;
$tabs-link-active-color: $cornflower;
$input-placeholder-color: $grey-text;
$input-focus-border-color: $cornflower;
$pagination-current-background-color: $cornflower;
$pagination-current-border-color: $cornflower;
$navbar-item-hover-color: $cornflower;
$navbar-item-active-color: $cornflower;

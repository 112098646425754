.accordion-root {
    margin: 11.6px 0 11.6px 0;
    border: solid 0.7px #e2e2e2;
    border-radius: 5px;

    .accordion-item-button {
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
        border: solid 0.7px #e2e2e2;
        cursor: pointer;
        padding: 18px;
        width: auto;
        text-align: left;
        border: none;
        outline: none;
    }

    .accordion-item-panel {
        -webkit-animation: fadein 0.35s ease-in;
        animation: fadein 0.35s ease-in;
    }
}

.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  z-index: 10;
}

.time-input-wrapper {
  width: 25%;

  @media screen and (max-width: 1440px) {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
  }
}

.time-inputs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1440px) {
    justify-content: end;
  }
}

.datepicker {
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 5%) !important;
}

.time-datepicker-container {
  width: 100%;
  height: 38px;
}

.form-wrapper {
  padding: 24px;
}

.checkbox-wrapper {
  display: flex;
}

.field-container {
  padding: 10px;
}

.checkbox-control {
  display: flex;
  align-items: center;
}

.text-style {
  font-size: 14px;
}

.action-button {
  width: 170px;
  height: 50px !important;
  padding: 15px 61px 15px 61px;
}

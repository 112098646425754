@import '../../../variables.scss';

.settings-wrapper {
  padding: 53px 56px 53px 44px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.settings-title-wrapper {
  padding: 0 0 12px 0;
}

.settings-column {
  width: 50%;
  margin: 1rem;
}

.settings-container {
  flex: 1;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: $white;
  padding: 21px 27px 25px 27px;
  margin-top: 25px;
}

.field-container {
  flex: 1;
  margin-right: 10px;
  margin-bottom: 23px;
}

.form-wrapper {
  display: flex;
}

:global .select,
:global select {
  width: 100% !important;
}

.action-button {
  width: 170px;
  height: 50px !important;
  padding: 15px 61px 15px 61px;
  margin: 0 !important;
  &:first-of-type {
    margin-right: 10px !important;
  }
}

.form-button {
  width: 100%;
}

:global .icon svg {
  margin-right: 5px;
}

.select {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
  background-color: whitesmoke !important;
}

@import '../../variables.scss';

.button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: $grey-text;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: bold;
    &:focus {
        outline: none;
    }
}
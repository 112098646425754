@import '../../../variables.scss';

.toolbar-item {
  margin: 0 12px 0 0;
  border-radius: 5px;
  display: flex;
  &:last-child {
    margin: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    //max-width: 200px;
    width: 100%;
  }
}

.toolbar-button {
  max-height: 40px;
  padding: 8px 10px;
  border-radius: 5px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.header-wrapper {
  background-color: #fafafa;
  margin: 0 !important;
  padding: 22.5px 38px 19.5px 38px;
}

.header-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.button-text {
  font-size: 14px;
  line-height: 1.29;
  color: $grey-text;
  padding-right: 10px;
}

.tabs {
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(204, 211, 223, 0.35);
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
}

.tabs-items {
  cursor: pointer;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: white;
  width: 100%;
  li {
    outline: none;
    font-size: 14px;
    line-height: 1.29;
  }

  :global li.selected-tab {
    font-weight: 600;
    color: $cornflower !important;
    border-bottom: 2px solid $cornflower !important;
  }
}

.button {
  width: 85px;
  height: 45px;
  border-radius: 5px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  border: none;
  font-size: 14px;
  line-height: 1.29;
  margin-right: 5px;
  outline: none;
  cursor: pointer;
}

.edit-button {
  background-color: #ffffff;
  color: $grey-text;
}

.save-button {
  width: 110px;
  background-color: $cornflower;
  color: white;
  font-weight: 600;
}

.width-100 {
  width: 100%;
}

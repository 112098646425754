@import '../../variables.scss';

.client-view-wrapper {
  padding: 53px 93px 0 93px;
}

.client-view-title {
  font-size: 22px;
  color: $black-title;
  margin: 0 0 14px 27px;
}

.client-view-project-wrapper {
  display: flex;
}

.client-view-project-details {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  flex: 1;
  margin-right: 27px;
}

.client-view-phases {
  flex: 1;
}

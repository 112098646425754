.timesheet-phases-table {
    border: 0;

    thead {
        tr {
            border: 0;

            th {
                color: #B0B7CA;
            }
        }
    }

    tbody {
        box-shadow: none !important;

        tr {
            border: 0;

            td {
                border: 0 !important;
            }
        }
    }
}

.delete-icon {
    color: #B0B7CA;
    cursor: pointer;
}
.container {
  position: relative;
}

.input {
  height: 35px;
  max-height: 35px;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  padding: 0 1rem;
  border: solid 1px lightgrey;
  border-radius: 4px;
  width: 150px;
}

.range-input {
  width: 300px;
}

.calendar-container {
  position: absolute;
  z-index: 1;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  text-align: center;
}

.top {
  bottom: 40px;
}

.bottom {
  top: 40px;
}

@import '../../../variables.scss';

.templates-wrapper {
  padding: 53px 56px 53px 44px;
  display: flex;
}

.templates-wrapper-column {
  flex: 1;
  &:first-of-type {
    margin-right: 32px;
  }
}

.templates-title-wrapper {
  padding: 0 0 12px 0;
}

.button-custom {
  height: 45px !important;
}

@import "../../variables.scss";

.filter {
    border-radius: 5px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 3px 4px 0 rgba(0, 0, 0, 0.05) !important;
    font-size: 12px!important;
    box-sizing: border-box;
    height: 45px!important;
    padding-left: 36px!important;
    font-family: Poppins;
    font-size: 14px;
}

.option {
    font-family: Poppins;
    font-size: 14px;
    padding-right: 10px;
    padding-top: 16px;
}

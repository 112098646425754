@import '../../../../../../variables.scss';

.project-description-users {
  padding: 0 38px 25px 38px;
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);

  a {
    color: $grey-text;

    &:hover {
      filter: brightness(50%);
    }
  }
}

.client-detail {
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;
}

.project-description-section-text {
  margin: 0;
  font-size: 14px;
  color: $grey-text;
}

@import '../../../../variables.scss';

.container {
  display: flex;
  width: 100%;
}

.full-page {
  padding: 1rem;
}

.columns-wrap {
  display: flex;
  max-width: 1300px;
  margin: 0 auto !important;
}
.practice-column-full-page {
  margin-top: 24px;
}

.columns-wrap-dashboard {
  display: flex;
  justify-content: space-between;
  max-width: 1350px;
  margin: 0 auto;
  .user-columns-dashboard {
    display: flex;
    justify-content: center;
    .cards-column:not(:last-child) {
      margin-right: 11px;
    }
  }
}

.user-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.5rem;
  .user {
    display: flex;
    align-items: center;
    .user-info {
      h4 {
        margin: 0;
      }
      .cards-number {
        font-size: 14px;
      }
    }
  }
  .user-focus-toggle {
    flex: 0 0 auto;
    display: inline-block;
    margin-right: 40px;
    opacity: 0.5;

    &.in-focus {
      opacity: 1;

      &:hover {
        opacity: 0.5;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
}

.users-container {
  width: 100%;

  .user-accordion {
    padding: 0.7rem;
    flex-grow: 1;
    background-color: $white;
  }

  .custom-divider {
    color: $grey-text !important;
  }

  .tip {
    font-size: 0.75rem;
    color: $grey-text;
    opacity: 0.6;
    text-align: center;
  }
}

.user-cards-container {
  padding: 0 1rem 1rem 1rem;
  margin: 1rem 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.columns-container {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
}

.cards-column {
  width: 100%;
  width: 239px;
  border-radius: 5px;
  box-shadow:
    0 1px 0 0 rgba(0, 0, 0, 0.06),
    0 2px 3px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  display: flex;
  flex-direction: column;

  .column-content-placeholder {
    color: lightgrey;
    border: 1px dashed lightgrey;
    h3 {
      padding: 1rem;
      text-align: center;
    }
  }

  .column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 0 1rem;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);

    .column-title-wrapper {
      display: flex;
      align-items: center;

      .column-title {
        margin: 0;
        padding: 1rem 0.5rem;
      }
    }

    .column-total-duration-wrapper {
      background-color: rgb(86, 86, 86);
      color: #ffffff;
      border-radius: 3px;
      padding: 0 0.5rem;
    }
  }

  .card-list {
    padding: 0.5rem;
    flex-grow: 1;
    min-height: 100px;
    .card-container {
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
      border: solid 0.7px #e2e2e2;
      border-radius: 5px;
      margin-bottom: 0.5rem;
      background-color: white;
      user-select: none;
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px 5px 0 0;
        min-height: 2.3vh;
        padding: 0.4rem 0.4rem 0.4rem 0.4rem;

        .title-wrap {
          color: white;
          width: 8.1rem;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
          }

          .subtitle {
            font-weight: lighter;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.83em;
          }
        }

        .card-stats {
          display: flex;
          flex-direction: column;
          align-items: end;
          margin-left: 0.4rem;
          max-width: 4.5rem;
          min-width: 2.3rem;

          .size-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.3rem;
            height: 1.8rem;
            background-color: $white;
            border-radius: 3px;
            margin-bottom: 0.4rem;

            .size {
              color: $black-title;
              font-weight: 300;
            }
          }

          .priority-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.3rem;
            height: 1.8rem;
            background-color: $white;
            border-radius: 3px;
            margin-bottom: 0.4rem;

            .priority {
              color: $black-title;
              font-weight: 300;
            }
          }

          .duration-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 1.8rem;
            background-color: $white;
            border-radius: 3px;
            padding-left: 1px;
            padding-right: 1px;

            .duration {
              color: $black-title;
              font-weight: 300;
            }
          }
        }
      }

      .card-footer-item {
        padding: 0.5rem;
        font-size: 0.8rem;
        color: $grey-text;
        display: flex;
        align-items: center;
        .name {
          margin-left: 0.5rem;
        }

        .icon-container {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
        }
      }
    }

    .dragging {
      border: none;
    }
  }
}

.dragging-over {
  transition: background-color 0.5s ease;
  background-color: $middle-grey;
}

.hide {
  opacity: 0;
}

.tabs {
  background-color: $white;
}

.tabs {
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(204, 211, 223, 0.35);
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
}

.tabs-items {
  max-width: 300px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  list-style-type: none;
  margin: 0 auto 3rem auto;
  padding: 0;

  li {
    min-width: 80px;
    text-align: center;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    flex: 1 1 0px;
    outline: none;
    font-size: 14px;
    line-height: 1.29;
    padding: 19px 30px 20px 30px;
    background-color: $white;
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
  :global li.selected-tab {
    font-weight: 600;
    color: $cornflower !important;
    border-bottom: 2px solid $cornflower !important;
    background-color: change-color($cornflower, $alpha: 0.1);
  }
}

// Toolbar
.toolbar-item {
  margin: 0 12px 0 12px;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
}

.toolbar-button {
  padding: 12px;
  background-color: #ffffff;
}

.overdue-by {
  color: $red;
  font-weight: bold;
  margin-left: 0.5rem;
}

@import "../../variables.scss";

.sign-up-page {
  height: calc(100% - 66px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-wrapper {
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: $white;
}

.sign-up-header {
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: $black-title;
  padding: 22px 30px;
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
}

.sign-up-form-wrapper {
  padding: 10px 32px 0 32px;
}

.fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
}
.field-container {
  flex: 1;
  padding-right: 10px;
  margin-bottom: 0 !important;
}

.align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-wrapper {
  display: flex;
  padding: 32px 0 !important;
  justify-content: center;
  align-items: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: $red;
}

.right-section {
  display: flex;
  justify-content: flex-end;
}

.action-button {
  margin: 0 10px;
  width: 170px;
  height: 50px !important;
}

.sign-in-text {
  margin-top: 21px;
  font-size: 14px;
  color: $grey-text;
}

.bold {
  font-weight: bold;
}

.link-style {
  font-size: 14px !important;
  color: $grey-text !important;
}

.form-label {
  font-size: 12px !important;
  font-weight: 600;
  color: #555c72;
}
@import '../../../../variables.scss';

.field-container {
  margin-top: 19px;
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(204, 211, 223, 0.35);
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
  background-color: #fafafa;
}

.tabs-items {
  cursor: pointer;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-left: 19px;
  li {
    outline: none;
    font-size: 14px;
    line-height: 1.29;
    padding: 19px 30px 20px 19px;
  }
  :global li.selected-tab {
    font-weight: 600;
    color: $cornflower !important;
    border-bottom: 2px solid $cornflower !important;
  }
}

.form {
  padding: 24px 37px 24px 37px;
}

$favorite-project-switch-color: #658aff;

.favorite-project-switch {
  &:hover {
    background-color: rgba(101, 138, 255, 0.1);
    color: $favorite-project-switch-color !important;
    border-bottom: 2px solid $favorite-project-switch-color !important;
  }

  font-weight: 600;
  border: none !important;
  color: #555c72 !important;
  font-size: 12px;
  height: 45px !important;
}

.favorite-project-switch__selected {
  @extend .favorite-project-switch;
  background-color: rgba(101, 138, 255, 0.1);
  color: $favorite-project-switch-color !important;
  border-bottom: 2px solid $favorite-project-switch-color !important;
}

#projects-table {
  height: 470px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.projects-table {
  table-layout: fixed;
  thead {
    tr {
      .project-header {
        width: 20%;
      }
      .status-header {
        width: 30%;
      }
      .determination-header {
        width: 20%;
      }
      .progress-header {
        width: 15%;
      }
    }
  }

  tr {
    cursor: pointer;
  }

  td {
    padding: 17px 0 17px 26px;
  }
}
.projects-table-header {
  z-index: 1;
  position: sticky;
  top: 0;
  padding: 8px 0 8px 26px !important;
  vertical-align: middle !important;
  background-color: #fafafa;
  font-weight: bold !important;
  box-shadow:
    0 1px 0 0 rgba(0, 0, 0, 0.06),
    0 2px 3px 0 rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #f5f5f5;
  }
}

.root {
  padding: 20px;
}

.completed-phase {
  background-color: #76c066;
  color: #ffffff;
  outline: none !important;
}

.phase-heading {
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
}

.phase-heading-wrapper,
.phase-heading-container,
.phase-icons {
  display: flex;
  align-items: center;
  margin-top: 0 !important;
}

.trash-icon {
  margin-bottom: 0 !important;
  margin-right: 1rem;
}

.info-icon {
  margin-bottom: 0 !important;
  & + .info-icon {
    margin-left: 1rem;
  }
}

.phase-inner-description {
  padding: 26px 15px 10px 15px;
}

.phase-inner-description,
.phase-inner-card {
  .phase-inner-title {
    font-weight: 600;
    display: flex;
    align-content: center;

    .phase-inner-title-icon {
      margin-right: 7px;
    }
  }
}

.phase-inner-card {
  .phase-inner-header {
    padding: 20px;
    background-color: #fafafa;
  }
}

.phase-statistics {
  font-size: 14px;
}

.phase-input-field {
  font-size: inherit;
  color: inherit;
  border: 1px solid transparent;
}

.phase-description-field {
  height: 150px;
}

@import '../../variables.scss';

.header-title {
  font-size: 22px;
  color: $black-title;
  margin: 0;
  margin-bottom: 5px;
  inline-size: 300px;
  overflow-wrap: break-word;
}

.header-fit-title {
  inline-size: auto;
}

@import '../../../variables.scss';

.projects-wrapper {
  padding: 53px 56px 53px 44px;
}

.projects-title-wrapper {
  padding: 0 0 12px 0;
}

.pagination-disabled {
  border-color: #dbdbdb;
  box-shadow: none;
  color: #7a7a7a;
  opacity: 0.5;
  cursor: default !important;
}

.button-custom {
  min-width: 170px;
  min-height: 45px;
}

@import './variables.scss';

$text: #555c72;

$table-color: #555c72;
$table-background-color: transparent;
$table-head-cell-color: #555c72;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/helpers/_all.sass';
@import 'bulma/sass/components/navbar.sass';
@import 'bulma/sass/components/tabs.sass';
@import 'bulma/sass/components/level.sass';
@import 'bulma/sass/components/modal.sass';
@import 'bulma/sass/components/pagination.sass';
@import 'bulma/sass/components/dropdown.sass';
@import 'bulma/sass/components/message.sass';
@import 'bulma/sass/elements/progress.sass';
@import 'bulma/sass/elements/tag.sass';
@import 'bulma/sass/elements/title.sass';
@import 'bulma/sass/elements/table.sass';
@import 'bulma/sass/elements/button.sass';
@import 'bulma/sass/elements/icon.sass';
@import 'bulma/sass/elements/box.sass';
@import 'bulma/sass/elements/container.sass';
@import 'bulma/sass/elements/notification.sass';
@import 'bulma/sass/layout/section.sass';
@import 'bulma/sass/grid/columns.sass';
@import 'bulma/sass/form/_all.sass';

@import '~@creativebulma/bulma-divider';

@import './datepicker.scss';

html {
  font-family: 'Poppins', sans-serif;
  background-color: #fafafa;
}

body {
  margin: 0;
  padding: 0;
  color: $text;
}

.root {
  // height: 100%;
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0 !important;
}

// Bulma overwrites
.table {
  thead {
    tr {
      th {
        font-weight: normal;
        border: none;
        padding: 20px 0 8px 26px;
        font-size: 12px;
      }
    }
  }

  tbody {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
    tr {
      td {
        background-color: $white;
        vertical-align: middle;
        border-bottom: 1px solid rgba(204, 211, 223, 0.35);
        padding: 20px 0 20px 26px;
        font-size: 14px;
      }
    }
  }
}

.input {
  border-radius: 5px !important;
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  border: solid 0.7px #e2e2e2;
  background-color: #ffffff;
  width: 100%;
  padding: 11px;
  box-sizing: border-box;
}

.control.has-icons-right .icon {
  top: 6px;
}

.with-shadow {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.is-borderless {
  border: none !important;
}

.is-paddingless {
  padding: 0 !important;
}

.no-flex-wrap {
  flex-wrap: nowrap !important;
}

.notification {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.21;
  color: $white;
  margin-right: 120px;
}

.notification.is-success {
  background-color: rgba(118, 192, 102, 0.8);
}

.notification.is-danger {
  background-color: rgba(195, 57, 57, 0.8);
}

.delete {
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: 0;
  position: relative;
  vertical-align: top;
  width: 20px;
}

.delete:before {
  height: 2px;
  width: 50%;
  background-color: #fff;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}

.delete:after {
  height: 50%;
  width: 2px;
  background-color: #fff;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}

.input[disabled] {
  color: #000000;
}

.pagination {
  list-style: none;
  justify-content: flex-end;
  .selected {
    a {
      background-color: $cornflower;
      color: $white;
    }
  }
  li {
    cursor: pointer;
    a {
      width: 35px;
      height: 35px;
      border-radius: 5px;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.05);
      background-color: $white;
      box-sizing: border-box;
      font-family: Poppins;
      font-size: 14px;
      color: $text;
      border: none;
    }
  }
}

.button {
  font-size: 14px;
  text-align: center;
  font-family: Poppins;
}

.button[disabled] {
  border-color: transparent;
}

.button.is-info {
  background-color: $cornflower !important;
  font-weight: 600;
  color: $white;
}

.button-is-light {
  color: $grey-text;
  background-color: #eaebf0;
}

.label {
  font-size: 12px !important;
  font-weight: 600;
  color: $grey-text;
  text-align: left;
  padding-bottom: 9px;
}

.input {
  font-family: Poppins;
  font-size: 12px !important;
  color: #555c72;
}

select,
.form-control,
.react-select__control,
.react-select-progress__control {
  min-height: 45px !important;
  outline: none;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 0.7px #e2e2e2 !important;
  background-color: $white;
  font-family: Poppins !important;
  font-size: 12px !important;
  color: $grey-text !important;
  option {
    font-family: Poppins;
    font-size: 12px;
    color: $grey-text;
  }
}

.react-select-progress__control {
  min-width: 200px;
  border: none !important;
}

.react-select__menu,
.react-select-progress__menu {
  font-family: Poppins !important;
  font-size: 12px !important;
  z-index: 2 !important;
}

.react-select__value-container,
.react-select-progress__value-container {
  border-right: none !important;
}

.form-control {
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  outline: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.error {
  color: $red;
  font-size: 12px;
  position: absolute;
}

.link-button {
  text-decoration: none;
}

@media screen and (max-width: 1023.5px) {
  .is-hidden-touch {
    display: none !important;
  }

  .move-right-touch {
    text-align: right;
  }
}

@media screen and (min-width: 1023.5px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

.archived {
  background-color: rgba(101, 138, 255, 0.05) !important;
}

span.icon svg {
  margin: 0 !important;
}

.no-outline {
  outline: none !important;
}

textarea {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  outline: none;
}

.tags,
.tag {
  margin: 0 !important;
}
.LandbotLivechat-container,
.LandbotLivechat {
  z-index: 1;
  width: 20px;
}

.action-button {
  width: 170px;
  height: 50px;
  padding: 15px 61px 15px 61px;
}

.hide {
  display: none !important;
}

.bold {
  font-weight: bold;
}

.level {
  width: 100%;
}

.glowing-border {
  border-radius: 7px;
}

.glowing-border:focus,
.glowing-border:hover {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

.error-message {
  color: $red;
  font-size: 12px;
}

.link {
  color: #658aff;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    border-bottom: 2px solid #658aff;
    font-weight: 500;
  }
}

.user-button {
  font-size: 14px;
  cursor: pointer;
}

.new-project-button {
  font-size: 14px;
  font-weight: 600;
  min-width: 170px;
  min-height: 50px;
}

a {
  img.logo {
    max-height: 3.5rem;
  }
}

.burger {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.header-left {
  display: flex;
  align-items: center;
}

.impersonate-button {
  margin-left: 10px;
}

.dev-environment-banner {
  text-align: center;
  padding: 8px;
  background-color: yellow;
  text-transform: uppercase;
}

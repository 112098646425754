.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.duration-field {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.input-fields {
  display: flex;
  gap: 10px;
}

.input-field {
  display: flex;
  align-items: center;
}

.input-field label {
  margin-right: 5px;
}

.input-field input {
  width: 100px; /* Adjust the width as needed */
}

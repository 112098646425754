.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 1rem;
}

.view-records-button {
  text-decoration: none;
  margin-top: 1rem;
}

.download-button {
  a {
    text-decoration: none;
    color: #ffffff;
    margin: 2rem 0;
  }
}

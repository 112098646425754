@import '../../../../variables.scss';

.data-selectors {
  display: flex;
  list-style-type: none;
  margin: 0 0 1rem 0;
  padding: 0;

  li {
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    outline: none;
    line-height: 1.29;
    width: 80px;
    text-align: center;

    &.selected-data {
      background-color: rgba(101, 138, 255, 0.1);
      font-weight: bold;
      border-bottom: solid 3px $cornflower;
      button {
        color: $cornflower;
      }
    }

    &:not(:last-child),
    &:not(:first-child) {
      border-radius: 0;
    }
    button {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 100%;
      cursor: pointer;
      font-size: 14px;
      background-color: transparent;
      border: none;
      font: inherit;
      color: $grey-text;
    }
  }
  .li.selected-tab {
    font-weight: 600;
    color: $cornflower !important;
    border-bottom: 2px solid $cornflower !important;
  }
}

.search-input {
  margin-bottom: 1rem;
  max-width: 400px !important;
}

.checkbox-control {
    display: inline!important;
}

.input-checkbox {
    height: 20px;
    width: 20px;
    margin-left: 10px;
    border-color: #e2e2e2;
}

.label-wrapper {
    display: flex!important;
    align-items: center!important;
}

.error-message{
    color: red;
    font-size: 12px;
}

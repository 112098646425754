@import '../../../variables.scss';

.root {
  display: flex;
  justify-content: center;
}

.full-page {
  padding: 1rem;
}
.level {
  width: 100%;
  margin-bottom: 2rem !important;
  display: flex;
  justify-content: space-between;
}

.add-card-button {
  margin-left: 1rem;
}

.tabs-container {
  width: 100%;
}

.create-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tabs {
  background-color: $white;
}

.tabs {
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(204, 211, 223, 0.35);
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
}

.tabs-items {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    min-width: 80px;
    text-align: center;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    flex: 1 1 0px;
    outline: none;
    font-size: 14px;
    line-height: 1.29;
    background-color: $white;
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
  :global li.selected-tab {
    font-weight: 600;
    color: $cornflower !important;
    border-bottom: 2px solid $cornflower !important;
    background-color: change-color($cornflower, $alpha: 0.1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.toolbar-container {
  margin-bottom: 1rem;
}

.sidebar {
  -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
  -ms-animation: fadein 0.3s; /* Internet Explorer */
  animation: fadein 0.3s;
  z-index: 10;
  .sidebar-background {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .sidebar-content {
    min-width: 600px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    position: fixed !important;
    right: 0;
    max-height: 100%;
    top: 0;
    bottom: 0;
    width: 45%;
    padding-bottom: 4rem;
  }

  .sidebar-close-button {
    background-color: #000000;
    color: #ffffff;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.confirmation {
    -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
    -ms-animation: fadein 0.3s; /* Internet Explorer */
    animation: fadein 0.3s;

    .confirmation-background {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .confirmation-content {
        width: 700px;
        background-color: #FFFFFF;
    }
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.wrapper,
.container,
.icons {
  display: flex;
}

.icons {
  margin-bottom: 0 !important;
  margin-right: 1rem;
}

.drop-down-icon {
  margin-top: 0 !important;
}

@import '../../../variables.scss';

.root {
  padding: 53px 56px 53px 44px;
}

.metrics-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 12px 0;

  .metrics-filter-wrapper {
    display: flex;
    align-items: center;

    label {
      padding-right: 16px;
      font-weight: bold;
    }
  }
}

.loader {
  text-align: center;
}

.metrics-wrapper {
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: $white;
  padding: 21px 27px 25px 27px;
  &:nth-of-type(3) {
    margin-top: 25px;
  }

  .chart-wrapper {
    height: 450px;
  }
}

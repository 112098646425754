.card-container {
  background-color: #ffffff;
  width: 240px;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  min-height: 300px;
}

.card-container-item {
  display: flex;
  align-items: center;
  padding: 13px 27px 13px 27px;
  font-size: 14px;
  border: 1px solid #fafafa;
}

.card-header {
  border-radius: 5px 5px 0 0;
  background-color: #50b649;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.21;
  padding: 15px;
  min-height: 51px;
}

.card-body-item {
  background-color: #ffffff;
  padding-left: 28px;
}

#no-cards-container {
  text-align: center;
}

// Toolbar
.toolbar-item {
  margin: 0 12px 0 12px;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
}

.toolbar-button {
  padding: 12px;
  background-color: #ffffff;
}

@import '../../../../../../variables.scss';

.name-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.is-selected {
  background-color: $light-pea-green;
  border-radius: 10px 20px 10px 20px;
}

@import '../../../variables.scss';

.container {
  background: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  outline: none;
  line-height: 1.29;
  width: 140px;
  text-align: center;

  &.active {
    background-color: rgba(101, 138, 255, 0.1);
    font-weight: bold;
    border-bottom: solid 3px $cornflower;
    a.content {
      color: $cornflower;
    }
  }

  &:not(:last-child),
  &:not(:first-child) {
    border-radius: 0;
  }
  a.content {
    padding: 1rem;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    border: none;
    font: inherit;
    color: $grey-text;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

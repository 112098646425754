@import '../../../variables.scss';

.users-wrapper {
  padding: 53px 56px 53px 44px;
}

.users-title-wrapper {
  padding: 0 0 12px 0;
}

.pagination-disabled {
  border-color: #dbdbdb;
  box-shadow: none;
  color: #7a7a7a;
  opacity: 0.5;
  cursor: default !important;
}

.button-custom {
  min-width: 170px;
  min-height: 45px;
}

.users-title-header {
  display: flex;
  a {
    text-decoration: none;
    margin-left: 1rem;

    .download-text {
      margin-left: 1rem;
    }
  }
}

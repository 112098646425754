.task-item {
  padding: 19px;
  border: solid 0.7px #e2e2e2;
  margin-bottom: 0 !important;
  cursor: pointer;

  .task-completed,
  .task-completed[disabled] {
    background-color: #76c066;
    color: white;
  }

  .task-completed[disabled] {
    cursor: default;
  }
}

.button {
  margin: 15px;
}

.task-input-field {
  font-size: inherit;
  color: inherit;
  border: 1px solid transparent;
  width: 94%;
}

@import '../../variables.scss';

.login-page {
  height: calc(100vh - 66px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container {
  margin-bottom: 5rem;
  width: 600px;
}
.login-wrapper {
  width: 700px;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: $white;
}

.login-header {
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: $black-title;
  padding: 22px 30px;
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
}

.login-form-wrapper {
  padding: 10px 32px 0 32px;
}

.field-container {
  flex: 1;
  margin-right: 10px;
  margin-bottom: 0 !important;
}

.footer-wrapper {
  padding: 32px;
}

.align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-section {
  display: flex;
}

.action-button {
  margin: 0 10px;
  width: 170px;
  height: 50px !important;
}

.login-text {
  margin-top: 21px;
  font-size: 14px;
  color: $grey-text;
}

.bold {
  font-weight: bold;
}

.link-style {
  font-size: 14px !important;
  color: $grey-text !important;
}

.projects-wrapper {
  padding: 53px 56px 53px 44px;
}

.projects-title-wrapper {
  padding: 0 0 12px 0;
}

.impersonate-button {
  padding-left: 40px !important;
}

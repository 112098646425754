.timesheets-table-wrapper {
    max-height: 379px;
    overflow-y: auto;
    overflow-x: hidden;
}

.timesheets-table {    
    border-collapse: collapse;

    .timesheets-table-thead {
        background-color: #EFF1F5;
        border-radius: 7px 7px 0px 0px;
        font-size: 14px;

        tr {
            th:first-of-type {
                color: #242E48;
                text-align: left;
            }
    
            th {
                padding: 16px 0 16px 26px;
                border: none;
                color: #989EAD;
                font-weight: 400;
                text-align: center;
            }
        }
    }

    tbody {
        tr {
            border: 1px solid #E5E9F1;
            height: 70px;

            td {
                color: #242E48;
                font-weight: 500;
                vertical-align: middle;
            }

            .archived-row {
                background-color :#F8F9FB;
            }
        }
    }

    tfoot {
        background-color: #EFF1F5;
        border-radius: 7px 7px 0px 0px;
        font-size: 14px;

        tr {
            td:first-of-type {
                color: #242E48;
            }
    
            td {
                border: none;
                font-weight: 400;
                padding: 16px 0 16px 26px;
            }
        }
    }
}

.time-record-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 14px;
    min-height: 20px;
}

.time-record-box-day {
    @extend .time-record-box;
    cursor: pointer;
    background-color: #F8F9FB;
    padding: 10px;

    .is-archived {
        border: 1px solid #FFFFFF;
    }
}

.total-time-box {
    font-size: 14px;
}

.timesheets-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

// Toolbar
.toolbar-item {
    margin: 0 12px 0 12px;
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
}

.toolbar-button {
    padding: 12px;
    background-color: #FFFFFF;
}

.timesheet-search-bar-wrapper {
    width: 30%;
}

.search-input {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.75rem
}
@import '../../../../variables.scss';

.grey-background {
  max-width: 650px;
  max-height: 180px;
  background-color: $grey;
  @media (max-width: 768px) {
    max-height: 265px;
  }
}

.project-description-section-title {
  padding: 25px 5px 0 0;
  font-size: 14px;
  font-weight: 600;
  color: $black-title;
  align-items: center;
}

.progress-circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1640px) {
    flex-direction: column;
    align-items: center;
  }
}

.progress-circle-box {
  max-width: 275px;
  max-height: 126px;
  border-radius: 5px;
  background-color: $grey !important;
  display: flex;
  align-items: center;
  padding: 14px 18px;
  box-sizing: border-box;
  margin-top: 10px;
  &:first-of-type {
    margin-right: 20px;
  }
  @media (max-width: 1640px) {
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
}

.circle-progress {
  width: 98px;
  height: 98px;
}

.completed-phases-text {
  margin: 0;
  width: 115px;
  font-size: 12px;
  color: $grey-text;
}

.completed-phases-value {
  margin: 0;
  width: 56px;
  font-size: 20px;
  font-weight: 600;
  color: $black-title;
}

.project-description-progress-wrapper {
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
  padding: 0 38px 40px 38px;
}

.line-progress-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
}

.line-progress-title {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: $grey-text;
}

.line-progress-subtitle {
  margin: 0;
  font-size: 14px;
  color: $grey-text;
}

.line-progress {
  height: 6px !important;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.progress-text {
  margin: 0;
  font-size: 14px;
  color: $grey-text;
  text-align: center;
}

.project-description-status,
.project-description-field {
  padding: 0 38px 25px 38px;
  border-bottom: 1px solid rgba(204, 211, 223, 0.35);
}

.project-description-section-text {
  margin: 0;
  font-size: 14px;
  color: $grey-text;

  a {
    color: $grey-text;

    &:hover {
      text-decoration: none;
    }
  }
}

.input-text {
  margin-top: 5px;
  font-family: Poppins;
  font-size: 12px;
  padding: 8px 16px !important;
  box-sizing: border-box;
  height: 45px !important;
}

.textarea-input {
  font-family: Poppins;
  font-size: 12px !important;
  margin-top: 10px;
  box-sizing: border-box;
}

.client-detail {
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: 5px;
}

.subtitle {
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  color: $grey-text;
  font-style: italic;
}

@import "../../variables.scss";

.button-custom {
    min-width: 170px;
    min-height: 45px;
}

.favorite-project-switch {
    &:hover {
        background-color: rgba(101, 138, 255, 0.1);
        color: $cornflower !important;
        border-bottom: 2px solid $cornflower !important;
    }

    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 3px 4px 0 rgba(0, 0, 0, 0.05) !important;
    font-weight: 600;
    border: none !important;
    color: #555c72 !important;
    font-size: 12px;
    height: 45px !important;
}

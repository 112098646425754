.header {
    padding: 15px 30px;
    font-size: 22px;
    background-color: #fafafa;
    max-width: 100%;
    
    .header-content {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .close-button {
        background-color: #fafafa;
    }
}

.content {
    padding: 24px 37px 24px 37px;
}

.footer {
    padding: 37px;

    .action-button {
        width: 170px;
        height: 50px;
        padding: 15px 61px 15px 61px;
    }
}

.title {
    font-size: 22px!important;
}

@import '../../variables.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1rem;
}
.avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.has-counter {
    border: 3px solid white;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}
    
.is-small {
    width: 20px;
    height: 20px;
}
.is-medium {
    width: 30px;
    height: 30px;
}

.counter {
    width: 27px;
    height: 27px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    color: white;
    background-color: $cornflower;
    font-size: 14px;
    transform: translateY(-20px);
}
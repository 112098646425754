.field-container {
  margin-top: 19px;
}

.client-wrapper {
  display: flex;
  align-items: center;
}

.new-client-button {
  flex: 1;
  margin-top: 40px;
  margin-left: 10px;
  height: 45px !important;
}

.client-field {
  flex: 6;
}

.form-wrapper {
  padding: 24px 37px 24px 37px;
}
